import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import VueI18n from "vue-i18n";

export enum InputStyles {
  SEARCH = 'search',
  FIELD = 'field',
  AUTH = 'auth',
}

@Component
export default class InputText extends Vue {

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
  public hasError!: boolean;

  @Prop({
    type: String,
    required: false,
    default: null
  })
  public error!: string | null;

  @Prop({
    required: false,
    default: '',
  })
  public value!: any;

  @Prop({
    required: false,
    default: '',
  })
  public label!: string | VueI18n.TranslateResult;

  @Prop({
    required: false,
    default: '',
  })
  public placeholder!: string | VueI18n.TranslateResult;

  @Prop({
    type: String,
    required: false,
    default: InputStyles.FIELD,
  })
  public appearance!: InputStyles;

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  public disabled!: Boolean;

  @Prop({
    type: String,
    required: false,
    default: 'text',
  })
  public type!: String;

  @Prop({
    type: String,
    required: false,
    default: null,
  })
  public leftIcon!: String;
}
